import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';

import { Container } from 'components/container/Container';
import { Hero } from 'components/hero/Hero';

import { Button } from 'components/button/Button';
import { Search } from 'components/search/Search';
import { Banner } from 'components/banner/Banner';
import { Col } from 'components/col/Col';
import { Row } from 'components/row/Row';


interface IIssue {
  volume: number;
  issue: number;
  published: Date;
  chapters: IChapter[];
  editors: any;
}

interface IChapter {
  title: string;
}

export const query = graphql`
  query {
    allContentfulHefti (sort: { fields: [volume], order:DESC }) {
      edges {
        node {
          title
          id
          volume
          issue
          published
          editors {
            name
            id
          }
          chapters {
            title
            articles {
              title
              slug
              author {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default ({ data }: any) => {
  const { allContentfulHefti: { edges }} = data;

  // const heading = `${volume}. árgangur, ${issue}. hefti (${format(published, 'YYYY')})`;

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID!,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY!,
  );

  return (
    <>
    <Helmet title="Vefnir - vefrit félags um átjándu aldar fræði" />
    <Banner
      title="Vefrit Félags um átjándu aldar fræði"
      kicker="Ritið er elsta rafræna fræðitímaritið sem gefið er út á Íslandi"
    >
      <Search info="Leitið eftir greinum í vefni" searchClient={searchClient} />
    </Banner>
    <Container>
      <Row>
        {edges.slice(0, 3).map(({ node: { editors, volume, issue, title } }: { node: IIssue }) => {
          return (
            <Col col={6} key={`${volume}-${issue}`}>
              <Hero
                title={title}
                subtitle={`${volume}. árgangur, ${issue}. hefti`}
                contentHeading={editors && editors.length > 1 ? 'Ritstjórar:' : 'Ritstjóri:'}
                lines={editors && editors.map(({ name, id }: any) => ({ name, url: `/hofundar/${id}` }))}
              >
                <Button to={`/hefti/${volume}/${issue}`}>Skoða hefti</Button>
              </Hero>
            </Col>
          );
        })}
        <Col col={6}>
          <Hero
            title="Öll vefrit"
            subtitle={`Gefin hafa verið út ${edges.length} vefrit`}
            contentHeading=""
            lines={[]}
          >
            <Button to={`/hefti`}>Skoða öll hefti</Button>
          </Hero>
        </Col>
      </Row>

    </Container>

  </>
) ;
};
