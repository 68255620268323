import React, { useState } from 'react';

import {
  InstantSearch,
  Hits,
  PoweredBy,
  connectSearchBox,
} from 'react-instantsearch-dom';

import { useKeyDown } from 'hooks/use-keydown';

import { Item } from 'components/item/Item';

import s from './Search.scss';

interface ISearchProps {
  info: string;
  searchClient: any;
}

export const Search = ({ searchClient }: ISearchProps) => {

  const [query, setQuery] = useState(``);
  const [selection, setSelection] = useState(0);
  // const [focus, setFocus] = useState(false)
  const keys = useKeyDown();

  /*React.useEffect(() => {
    if (query.length > 0) {
      if (keys.includes(40)) {
        setSelection(selection + 1);
      } else if (keys.includes(38) && selection > 0) {
        setSelection(selection - 1);
      }
    }
  }, [keys]);*/

  return (
  <div className={s.search}>
    <div className={s.search__col}>
      <InstantSearch
        indexName="Articles"
        searchClient={searchClient}
        onSearchStateChange={(state) => setQuery(state.query)}
      >
        <SearchInput />
        {query.length > 0 && (
          <div className={s.search__results}>
            <Hits hitComponent={Hit} />
            <div className={s.search__powered}>
              <PoweredBy />
            </div>
          </div>
        )}

      </InstantSearch>
    </div>
  </div>
)};

const SearchInput = connectSearchBox(({ refine, ...rest }) => (
    <input
      type="text"
      placeholder="Leitaðu í Vefni"
      aria-label="Leit"
      onChange={e => refine(e.target.value)}
      className={s.search__input}
      {...rest}
    />
))

const Hit = ({ hit }: any) => {
  return (
    <div className={s.search__item}>
        <Item
          to={`/grein/${hit.slug}`}
          title={hit.title}
          subtitle={hit.author && hit.author.map(({ name, id: authorId }: any) => (<span key={authorId}>{name}</span>))}
        />
    </div>
  );
};
