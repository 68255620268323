import * as React from 'react';

import s from './Banner.scss';

interface IBannerProps {
  children: React.ReactNode;
  title: string;
  kicker: string;
}

export const Banner = ({ children, kicker, title }: IBannerProps) => (
  <div className={s.banner}>
      <div className={s.banner__wrapper}>

        <h1 className={s.banner__title}>{title}</h1>
        <h2 className={s.banner__kicker}>{kicker}</h2>
        <div className={s.banner__content}>
          {children}
        </div>
      </div>
  </div>
);
